var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Box, Unstable_Grid2 as Grid, Typography, Fab, Autocomplete, TextField, Alert, IconButton, Tooltip, FormLabel, ToggleButton, ToggleButtonGroup, FormGroup } from '@mui/material';
import { Add, Check, Close, DeleteForever, Filter1, Filter2, Filter3 } from '@mui/icons-material';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { createPartConfigProcess, deletePartConfigProcess, updatePartConfigProcess, upsertProcessFeatureValue } from '../../../services/configs';
class ProcessForm extends Component {
    constructor(props) {
        var _a;
        super(props);
        this.iconMap = { mainProcess: Filter1, postProcess: Filter2, productionRequirement: Filter3 };
        this.translationKeyPluralMap = { mainProcess: 'main_processes', postProcess: 'post_processes', productionRequirement: 'production_requirements' };
        this.translationKeySingularMap = { mainProcess: 'main_process', postProcess: 'post_process', productionRequirement: 'production_requirement' };
        this.classKeyMap = { mainProcess: 'process', postProcess: 'post_process', productionRequirement: 'production_requirement' };
        this.upsertProcessFeatureValue = (pcp_1, ppf_1, value_1, ...args_1) => __awaiter(this, [pcp_1, ppf_1, value_1, ...args_1], void 0, function* (pcp, ppf, value, commitChanges = false) {
            var _b, _c, _d, _f;
            this.setState({
                temporaryProcessFeatureValuesState: Object.assign(Object.assign({}, this.state.temporaryProcessFeatureValuesState), { [pcp.id]: Object.assign(Object.assign({}, this.state.temporaryProcessFeatureValuesState[pcp.id]), { [ppf.processFeatureKey]: value }) }),
            });
            if (commitChanges) {
                try {
                    const { data: upsertedProcessFeatureValue } = yield upsertProcessFeatureValue(this.props.partConfig.id, pcp.id, ppf.processFeatureKey, value.trim() || null);
                    this.props.updatePartConfigInState(Object.assign(Object.assign({}, this.props.partConfig), { partConfigProcesses: this.props.partConfig.partConfigProcesses.map(p => {
                            var _a;
                            return p.id === pcp.id ? Object.assign(Object.assign({}, p), { processFeatureValues: [
                                    ...((_a = p.processFeatureValues) === null || _a === void 0 ? void 0 : _a.filter(pfv => pfv.processFeatureTypeKey !== ppf.processFeatureKey)) || [],
                                    upsertedProcessFeatureValue,
                                ] }) : p;
                        }) }));
                }
                catch (err) {
                    console.error(err);
                    this.props.enqueueSnackbar(this.props.t('pc_mdl_update_data_form_error'), { variant: 'error' });
                    this.setState({
                        temporaryProcessFeatureValuesState: Object.assign(Object.assign({}, this.state.temporaryProcessFeatureValuesState), { [pcp.id]: Object.assign(Object.assign({}, this.state.temporaryProcessFeatureValuesState[pcp.id]), { [ppf.processFeatureKey]: ((_c = (_b = pcp.processFeatureValues) === null || _b === void 0 ? void 0 : _b.find(pfv => pfv.processFeatureTypeKey === ppf.processFeatureKey)) === null || _c === void 0 ? void 0 : _c.value) || ((_f = (_d = pcp.processFeatureValues) === null || _d === void 0 ? void 0 : _d.find(pfv => pfv.processFeatureTypeKey === ppf.processFeatureKey)) === null || _f === void 0 ? void 0 : _f.valueAuto) || '' }) }),
                    });
                }
            }
        });
        this.renderProcessFeatureForm = (pcp, ppf, pfv) => {
            var _a, _b, _c, _d, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
            switch ((_a = ppf.processFeature) === null || _a === void 0 ? void 0 : _a.dataTypeKey) {
                case 'text':
                case 'string':
                    return (_jsxs(FormGroup, { children: [_jsx(FormLabel, { required: ppf.required, error: ppf.required && !((pfv === null || pfv === void 0 ? void 0 : pfv.value) || (pfv === null || pfv === void 0 ? void 0 : pfv.valueAuto)), children: this.props.t(`processfeatures:${(_b = ppf.processFeature) === null || _b === void 0 ? void 0 : _b.key}`) }), _jsx(TextField, { fullWidth: true, required: ppf.required, error: ppf.required && !((pfv === null || pfv === void 0 ? void 0 : pfv.value) || (pfv === null || pfv === void 0 ? void 0 : pfv.valueAuto)), value: ((_d = (_c = this.state.temporaryProcessFeatureValuesState) === null || _c === void 0 ? void 0 : _c[pcp.id]) === null || _d === void 0 ? void 0 : _d[ppf.processFeatureKey]) || '', onChange: (e) => this.upsertProcessFeatureValue(pcp, ppf, e.target.value), onBlur: (e) => this.upsertProcessFeatureValue(pcp, ppf, e.target.value, true), variant: 'outlined', multiline: ((_f = ppf.processFeature) === null || _f === void 0 ? void 0 : _f.dataTypeKey) === 'text', size: 'small' })] }));
                case 'int':
                case 'double':
                    return (_jsxs(FormGroup, { children: [_jsx(FormLabel, { required: ppf.required, error: ppf.required && !((pfv === null || pfv === void 0 ? void 0 : pfv.value) || (pfv === null || pfv === void 0 ? void 0 : pfv.valueAuto)), children: this.props.t(`processfeatures:${(_g = ppf.processFeature) === null || _g === void 0 ? void 0 : _g.key}`) }), _jsx(TextField, { fullWidth: true, variant: 'outlined', value: ((_j = (_h = this.state.temporaryProcessFeatureValuesState) === null || _h === void 0 ? void 0 : _h[pcp.id]) === null || _j === void 0 ? void 0 : _j[ppf.processFeatureKey]) || '', onChange: (e) => this.upsertProcessFeatureValue(pcp, ppf, e.target.value), onBlur: (e) => this.upsertProcessFeatureValue(pcp, ppf, e.target.value, true), required: ppf.required, error: ppf.required && !((pfv === null || pfv === void 0 ? void 0 : pfv.value) || (pfv === null || pfv === void 0 ? void 0 : pfv.valueAuto)), size: 'small', type: 'number' })] }));
                case 'bool':
                    return (_jsxs(FormGroup, { children: [_jsx(FormLabel, { required: ppf.required, error: ppf.required && !((pfv === null || pfv === void 0 ? void 0 : pfv.value) || (pfv === null || pfv === void 0 ? void 0 : pfv.valueAuto)), children: this.props.t(`processfeatures:${(_k = ppf.processFeature) === null || _k === void 0 ? void 0 : _k.key}`) }), _jsxs(ToggleButtonGroup, { exclusive: true, size: 'small', value: ((_m = (_l = this.state.temporaryProcessFeatureValuesState) === null || _l === void 0 ? void 0 : _l[pcp.id]) === null || _m === void 0 ? void 0 : _m[ppf.processFeatureKey]) || '', onChange: (_e, newValue) => this.upsertProcessFeatureValue(pcp, ppf, newValue, true), sx: (theme) => ({
                                    '& .MuiToggleButton-root': {
                                        borderColor: ppf.required && !((pfv === null || pfv === void 0 ? void 0 : pfv.value) || (pfv === null || pfv === void 0 ? void 0 : pfv.valueAuto)) ? theme.palette.error.main : undefined,
                                    },
                                }), children: [_jsx(ToggleButton, { value: 'true', children: _jsx(Check, {}) }), _jsx(ToggleButton, { value: 'false', children: _jsx(Close, {}) })] })] }));
                case 'select':
                    return (_jsxs(FormGroup, { children: [_jsx(FormLabel, { required: ppf.required, error: ppf.required && !((pfv === null || pfv === void 0 ? void 0 : pfv.value) || (pfv === null || pfv === void 0 ? void 0 : pfv.valueAuto)), children: this.props.t(`processfeatures:${(_o = ppf.processFeature) === null || _o === void 0 ? void 0 : _o.key}`) }), _jsx(Autocomplete, { options: ((_p = ppf.selectOptions) === null || _p === void 0 ? void 0 : _p.map(so => so.selectOptionKey)) || [], getOptionLabel: option => this.props.t(`processfeatures:${option}`), value: ((_r = (_q = this.state.temporaryProcessFeatureValuesState) === null || _q === void 0 ? void 0 : _q[pcp.id]) === null || _r === void 0 ? void 0 : _r[ppf.processFeatureKey]) || '', onChange: (_e, value) => this.upsertProcessFeatureValue(pcp, ppf, value, true), fullWidth: true, disableClearable: true, renderInput: params => _jsx(TextField, Object.assign({}, params, { variant: 'outlined', required: ppf.required, error: ppf.required && !((pfv === null || pfv === void 0 ? void 0 : pfv.value) || (pfv === null || pfv === void 0 ? void 0 : pfv.valueAuto)) })), size: 'small' })] }));
                default:
                    return _jsx(_Fragment, {});
            }
        };
        this.addProcess = (process) => __awaiter(this, void 0, void 0, function* () {
            var _g, _h;
            try {
                let relativePartConfigProcess;
                const position = this.props.class === 'productionRequirement'
                    ? 'last'
                    : ((_h = (_g = this.props.partConfig) === null || _g === void 0 ? void 0 : _g.partConfigProcesses) === null || _h === void 0 ? void 0 : _h.length)
                        ? 'after'
                        : 'first';
                if (position === 'after') {
                    relativePartConfigProcess = this.props.classPartConfigProcesses.length > 0
                        ? this.props.classPartConfigProcesses[this.props.classPartConfigProcesses.length - 1]
                        : this.props.partConfig.partConfigProcesses.findLast(pcp => { var _a; return ((_a = pcp.process) === null || _a === void 0 ? void 0 : _a.class) === 'process'; });
                }
                const { data: newPartConfigProcess } = yield createPartConfigProcess(this.props.partConfig.id, {
                    processId: process.id,
                    position,
                    relativePartConfigProcessId: relativePartConfigProcess === null || relativePartConfigProcess === void 0 ? void 0 : relativePartConfigProcess.id,
                    include: {
                        process: true,
                        manufacturingAnalyses: {
                            include: {
                                batchSize: true,
                            },
                        },
                        processFeatureValues: true,
                    },
                });
                this.props.updatePartConfigInState(Object.assign(Object.assign({}, this.props.partConfig), { partConfigProcesses: [
                        ...this.props.partConfig.partConfigProcesses,
                        newPartConfigProcess,
                    ] }));
                this.setState({
                    addProcess: false,
                    temporaryProcessFeatureValuesState: Object.assign(Object.assign({}, this.state.temporaryProcessFeatureValuesState), { [newPartConfigProcess.id]: {} }),
                });
            }
            catch (err) {
                console.error(err);
                this.props.enqueueSnackbar(this.props.t('pc_mdl_update_data_form_error'), { variant: 'error' });
            }
        });
        this.updateProcess = (pcp, newProcess) => __awaiter(this, void 0, void 0, function* () {
            try {
                const { data: updatedProcess } = yield updatePartConfigProcess(this.props.partConfig.id, pcp.id, {
                    processId: newProcess.id,
                    include: {
                        process: true,
                        manufacturingAnalyses: {
                            include: {
                                batchSize: true,
                            },
                        },
                        processFeatureValues: true,
                    },
                });
                this.props.updatePartConfigInState(Object.assign(Object.assign({}, this.props.partConfig), { partConfigProcesses: this.props.partConfig.partConfigProcesses.map(p => p.id === pcp.id ? updatedProcess : p) }));
            }
            catch (err) {
                console.error(err);
                this.props.enqueueSnackbar(this.props.t('pc_mdl_update_data_form_error'), { variant: 'error' });
            }
        });
        this.deleteProcess = (pcp) => __awaiter(this, void 0, void 0, function* () {
            try {
                yield deletePartConfigProcess(this.props.partConfig.id, pcp.id);
                this.props.updatePartConfigInState(Object.assign(Object.assign({}, this.props.partConfig), { partConfigProcesses: this.props.partConfig.partConfigProcesses.filter(p => p.id !== pcp.id) }));
            }
            catch (err) {
                console.error(err);
                this.props.enqueueSnackbar(this.props.t('pc_mdl_update_data_form_error'), { variant: 'error' });
            }
        });
        let temporaryProcessFeatureValuesState = {};
        (_a = props.classPartConfigProcesses) === null || _a === void 0 ? void 0 : _a.forEach(pcp => {
            var _a;
            temporaryProcessFeatureValuesState[pcp.id] = {};
            (_a = pcp.processFeatureValues) === null || _a === void 0 ? void 0 : _a.forEach(pfv => {
                temporaryProcessFeatureValuesState[pcp.id][pfv.processFeatureTypeKey] = pfv.value || pfv.valueAuto || '';
            });
        });
        this.state = {
            addProcess: false,
            temporaryProcessFeatureValuesState,
        };
    }
    render() {
        var _a;
        const { t, processes, allProcesses, classPartConfigProcesses } = this.props;
        const Icon = this.iconMap[this.props.class];
        return (_jsxs(Box, { className: 'configurator-root-box', children: [_jsx(Box, { display: 'flex', alignItems: 'center', children: _jsx(Icon, { color: 'success' }) }), _jsx(Box, { display: 'flex', alignItems: 'center', children: _jsx(Typography, { fontSize: 20, fontWeight: 600, children: t(this.translationKeyPluralMap[this.props.class]) }) }), _jsx(Box, {}), _jsx(Box, { children: _jsxs(Grid, { container: true, spacing: 1, children: [classPartConfigProcesses === null || classPartConfigProcesses === void 0 ? void 0 : classPartConfigProcesses.map((pcp) => {
                                var _a, _b;
                                return (_jsx(Grid, { xs: 12, children: _jsx(Alert, { icon: false, variant: 'outlined', color: 'success', sx: { '.MuiAlert-message': { width: '100%', overflow: 'hidden' } }, children: _jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { xs: 11, children: _jsx(Autocomplete, { options: processes.filter(p => p.class === this.classKeyMap[this.props.class]), fullWidth: true, getOptionLabel: option => t(`processes:${option.key}`), isOptionEqualToValue: (option, value) => option.key === value.key, renderInput: params => _jsx(TextField, Object.assign({}, params, { variant: 'outlined', size: 'small' })), value: pcp.process, onChange: (_e, value) => this.updateProcess(pcp, value), disableClearable: true }) }), _jsx(Grid, { xs: 1, textAlign: 'right', children: _jsx(Tooltip, { title: t('delete_this_process'), children: _jsx(IconButton, { onClick: () => this.deleteProcess(pcp), disabled: this.props.class === 'mainProcess' && classPartConfigProcesses.length === 1 && this.props.partConfig.partConfigProcesses.length > 1, children: _jsx(DeleteForever, {}) }) }) }), (_b = (_a = processes.find(p => { var _a; return p.key === ((_a = pcp.process) === null || _a === void 0 ? void 0 : _a.key); })) === null || _a === void 0 ? void 0 : _a.partsProcessFeatures) === null || _b === void 0 ? void 0 : _b.map(ppf => {
                                                    var _a;
                                                    return (_jsx(Grid, { xs: 6, children: this.renderProcessFeatureForm(pcp, ppf, (_a = pcp.processFeatureValues) === null || _a === void 0 ? void 0 : _a.find(pfv => pfv.processFeatureTypeKey === ppf.processFeatureKey)) }));
                                                })] }) }) }));
                            }), this.state.addProcess && (_jsx(Grid, { xs: 12, children: _jsx(Alert, { icon: false, variant: 'outlined', color: 'success', sx: { '.MuiAlert-message': { width: '100%', overflow: 'hidden' } }, children: _jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { xs: 11, children: _jsx(Autocomplete, { options: allProcesses.filter(p => p.class === this.classKeyMap[this.props.class]), fullWidth: true, getOptionLabel: option => t(`processes:${option.key}`), isOptionEqualToValue: (option, value) => option.key === value.key, renderInput: params => _jsx(TextField, Object.assign({}, params, { variant: 'outlined', size: 'small' })), onChange: (_e, value) => this.addProcess(value) }) }), _jsx(Grid, { xs: 1, textAlign: 'right', children: _jsx(Tooltip, { title: t('delete_this_process'), children: _jsx(IconButton, { onClick: () => this.setState({ addProcess: false }), children: _jsx(DeleteForever, {}) }) }) })] }) }) })), _jsx(Grid, { xs: 12, children: _jsx(Box, { display: 'flex', justifyContent: 'center', children: _jsxs(Fab, { color: 'success', variant: 'extended', sx: { boxShadow: 2, pr: 2, color: 'white' }, size: 'small', disabled: this.props.class !== 'mainProcess'
                                            && !((_a = this.props.partConfig.partConfigProcesses) === null || _a === void 0 ? void 0 : _a.some((pcp) => { var _a; return ((_a = pcp.process) === null || _a === void 0 ? void 0 : _a.class) === this.classKeyMap['mainProcess']; })), onClick: () => this.setState({ addProcess: true }), children: [_jsx(Add, { sx: { mr: 1 } }), t(`add_a_${this.translationKeySingularMap[this.props.class]}`)] }) }) })] }) })] }));
    }
}
const mapStateToProps = (state) => ({
    processes: state.general.processes,
    allProcesses: state.general.allProcesses,
});
class Processes extends Component {
    render() {
        var _a, _b, _c, _d, _f, _g;
        return (_jsxs(_Fragment, { children: [_jsx(ProcessForm, Object.assign({}, this.props, { class: 'mainProcess', partConfig: this.props.partConfigInEditMode.partConfig, classPartConfigProcesses: (_b = (_a = this.props.partConfigInEditMode.partConfig) === null || _a === void 0 ? void 0 : _a.partConfigProcesses) === null || _b === void 0 ? void 0 : _b.filter(pcp => { var _a; return ((_a = pcp.process) === null || _a === void 0 ? void 0 : _a.class) === 'process'; }) })), _jsx(ProcessForm, Object.assign({}, this.props, { class: 'postProcess', partConfig: this.props.partConfigInEditMode.partConfig, classPartConfigProcesses: (_d = (_c = this.props.partConfigInEditMode.partConfig) === null || _c === void 0 ? void 0 : _c.partConfigProcesses) === null || _d === void 0 ? void 0 : _d.filter(pcp => { var _a; return ((_a = pcp.process) === null || _a === void 0 ? void 0 : _a.class) === 'post_process'; }) })), _jsx(ProcessForm, Object.assign({}, this.props, { class: 'productionRequirement', partConfig: this.props.partConfigInEditMode.partConfig, classPartConfigProcesses: (_g = (_f = this.props.partConfigInEditMode.partConfig) === null || _f === void 0 ? void 0 : _f.partConfigProcesses) === null || _g === void 0 ? void 0 : _g.filter(pcp => { var _a; return ((_a = pcp.process) === null || _a === void 0 ? void 0 : _a.class) === 'production_requirement'; }) }))] }));
    }
}
const connector = connect(mapStateToProps);
export default withSnackbar(withTranslation(['partconfigurator', 'processes', 'processfeatures'])(connector(Processes)));
